import TagManager from 'react-gtm-module'
import getReactPixel from '@helpers/getReactPixel'
import logger from '@helpers/logger'
import isServerSide from '@helpers/misc/isServerSide'
import GA4React from 'ga-4-react'

export const providers = {
  GA4: 'ga4',
  PIXEL: 'pixel',
  GTM: 'gtm'
}

const pixelSendEvent = (eventName: string, data: any, eventExtraData: {eventID: string}) => {
  const reactPixel = getReactPixel()
  reactPixel?.fbq('track', eventName, data, eventExtraData)
}

export const recommendedEvents = {
  ADD_TO_CART: {
    pixel: 'AddToCart',
    ga4: 'add_to_cart',
    gtm: 'add_to_cart'
  },
  BEGIN_CHECKOUT: {
    pixel: 'InitiateCheckout',
    ga4: 'begin_checkout',
    gtm: 'begin_checkout'
  },
  PURCHASE: {
    pixel: 'Purchase',
    ga4: 'purchase',
    gtm: 'purchase'
  },
  SELECT_ITEM: {
    ga4: 'select_item',
    gtm: 'select_item'
  }
}

const ga4SendEvent = (
  action,
  additionalData,
  custom = false,
  label = null,
  category = null,
  nonInteraction = false
) => {
  if (isServerSide()) return
  const isInitialized = GA4React.isInitialized()
  if (!isInitialized) {
    return
  }
  const ga4 = GA4React.getGA4React()
  if (!ga4) {
    logger.warn('Could not get GA4React')
    return
  }
  let data = {...additionalData}
  if (custom) {
    data = {...data, event_label: label, event_category: category, nonInteraction}
  }
  ga4.gtag('event', action, data)
}

const gtmSendEvent = dataLayer => {
  TagManager.dataLayer({dataLayer})
}

export const analyticsEventManager = {
  pixel: pixelSendEvent,
  ga4: ga4SendEvent,
  gtm: gtmSendEvent
}
