import RGA from 'react-ga'

let ReactGA: typeof RGA | null = null

export const setReactGA = (reactGA: typeof RGA | null) => {
  ReactGA = reactGA
}

export const getReactGA = (): typeof RGA | null => {
  return ReactGA
}
