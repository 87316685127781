import gql from 'graphql-tag'

export default gql`
  mutation sendEcommerceEvent(
    $eventName: String
    $eventIdentifier: String
    $actionSource: String
    $orderId: ID
    $currency: String
    $items: [JSON]
    $totalPrice: Float
    $origin: String
    $customParams: JSON
  ) {
    sendEcommerceEvent(
      eventName: $eventName
      eventIdentifier: $eventIdentifier
      actionSource: $actionSource
      orderId: $orderId
      currency: $currency
      items: $items
      totalPrice: $totalPrice
      origin: $origin
      customParams: $customParams
    )
  }
`
