import {getReactGA} from '@helpers/getReactGA'
import getCurrencyCode from '@i18n/getCurrencyCode'

import {analyticsEventManager, providers, recommendedEvents} from './eventAnalytics'
import trackPageView from './trackPageView'

export default function trackAddToCart(
  {
    product,
    item,
    amount
  }: {
    product?: any
    item?: {
      unitPrice: number
      totalPrice: number
    }
    amount?: number
  },
  {eventId}: {eventId: string}
) {
  if (!product || !item) return
  const ReactGA = getReactGA()

  const currency = getCurrencyCode()

  ReactGA?.set({currencyCode: currency})
  ReactGA?.plugin.execute('ec', 'addProduct', {
    id: product._id,
    name: product.name,
    price: item.unitPrice,
    category: product.categories.length ? product.categories[0].internalName : null,
    quantity: amount,
    currency
  })
  ReactGA?.plugin.execute('ec', 'setAction', 'add')
  ReactGA?.event({
    category: 'UX',
    action: 'addProductToCart',
    label: 'Agregar al carro'
  })

  const analyticPixelSendEvent = analyticsEventManager[providers.PIXEL]
  const pixelData = {
    content_name: product.name,
    content_type: 'product',
    content_ids: product._id,
    value: item.unitPrice,
    currency
  }
  analyticPixelSendEvent(recommendedEvents.ADD_TO_CART.pixel, pixelData, {eventID: eventId})

  const analyticga4SendEvent = analyticsEventManager[providers.GA4]
  const ga4Data = {
    currency,
    value: item.unitPrice,
    items: [
      {item_id: product._id, item_name: product.name, price: item.unitPrice, quantity: amount}
    ]
  }
  analyticga4SendEvent(recommendedEvents.ADD_TO_CART.ga4, ga4Data)

  const analyticsgtmSendEvent = analyticsEventManager[providers.GTM]
  const gtmData = {
    event: recommendedEvents.ADD_TO_CART.gtm,
    ecommerce: {
      items: [
        {item_id: product._id, item_name: product.name, price: item.unitPrice, quantity: amount}
      ]
    }
  }
  analyticsgtmSendEvent(gtmData)

  trackPageView('/virtual/add_to_cart')
}
