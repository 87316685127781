import {getReactGA} from '@helpers/getReactGA'
import getReactPixel from '@helpers/getReactPixel'
import GA4React from 'ga-4-react'

export default function trackPageView(pathname) {
  const pixel = getReactPixel()
  if (!pathname.includes('/virtual')) {
    pixel?.pageView()
  }
  const ReactGA = getReactGA()
  ReactGA?.pageview(pathname)
  if (GA4React.isInitialized()) {
    const ga4 = GA4React.getGA4React()
    if (ga4) {
      ga4.pageview(pathname)
    }
  }
}
