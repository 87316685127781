import {WebsiteInitialData_CachedQuery} from '@data/__generated__/types.main'
import logger from '@helpers/logger'
import useCookiesAccepted from '@hooks/useCookiesAccepted'
import getCurrencyCode from '@i18n/getCurrencyCode'
import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'
import useInitialData from '@page-components/Layout/useInitialData'
import {useMutate} from 'apollo-hooks'
import GA4React from 'ga-4-react'
import {GA4ReactResolveInterface} from 'ga-4-react/dist/models/gtagModels'
import {v4 as uuidv4} from 'uuid'

import sendEcommerceEventMutation from './eventAnalytics/sendEcommerceEventMutation'
import trackAddToCart from './trackAddToCart'
import trackInitCheckout from './trackInitCheckout'
import trackPageView from './trackPageView'
import trackProductView from './trackProductView'
import trackPurchase from './trackPurchase'

interface EventParams {
  product?: any
  amount?: number
  item?: any
  order?: any
  cart?: any
}

export enum ECOMMERCE_EVENTS {
  PAGEVIEW = 'PageView',
  VIEWCONTENT = 'ViewContent',
  INITCHECKOUT = 'InitiateCheckout',
  ADDTOCART = 'AddToCart',
  PURCHASE = 'Purchase'
}

async function gtag(ga4: GA4ReactResolveInterface, method: string, ga4Code: string, value: string) {
  return new Promise((resolve, reject) => {
    ga4.gtag(method, ga4Code, value, result => {
      resolve(result)
    })
    setTimeout(() => {
      reject('timeout')
    }, 1000)
  })
}

/**
 * Based on the data required for GA4 Measurement protocol https://developers.google.com/analytics/devguides/collection/protocol/ga4?hl=es
 * @param initialData
 * @returns {Promise<{clientId: string, sessionId: string}>}
 */
async function customParamsToEvent(
  initialData: WebsiteInitialData_CachedQuery
): Promise<{clientId?: string; sessionId?: string}> {
  const customParams = {}
  const ga4Code = initialData.integrations?.integrations?.googleAnalyticsV4Id
  if (ga4Code && GA4React.isInitialized()) {
    const ga4 = GA4React.getGA4React()
    if (!ga4) {
      return customParams
    }
    const clientId = await gtag(ga4, 'get', ga4Code, 'client_id')
    customParams['clientId'] = clientId

    const sessionId = await gtag(ga4, 'get', ga4Code, 'session_id')
    customParams['sessionId'] = sessionId
  }
  return customParams
}

async function serverSendEvent(
  eventName: string,
  mutate: any,
  data: {
    currency: string
    eventId: string
    source: string
    totalPrice?: number
    items?: any[]
    orderId?: string
  },
  initialData: WebsiteInitialData_CachedQuery
) {
  try {
    if (!mutate) return
    const {orderId, eventId, source, items, currency, totalPrice} = data
    const variables = {
      eventName,
      eventIdentifier: eventId,
      currency,
      totalPrice,
      actionSource: source,
      origin: 'web',
      ...(items && {items}),
      ...(orderId && {orderId})
    }

    const customParams = await customParamsToEvent(initialData)
    variables['customParams'] = customParams

    await mutate({
      mutation: sendEcommerceEventMutation,
      variables
    })
  } catch (error) {
    logger.warn('could not send event to ecommerceEvents', {error})
  }
}

export default function useTrackEcommerceEvent() {
  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()
  const mutate = useMutate()
  const initialData = useInitialData()
  const cookies = useCookiesAccepted()
  const trackEcommerceEvent = async (
    event: ECOMMERCE_EVENTS,
    params: EventParams
  ): Promise<void> => {
    try {
      function getEventServerData(
        event: ECOMMERCE_EVENTS,
        params: EventParams
      ): {
        currency: string
        eventId: string
        source: string
        totalPrice?: number
        items?: any[]
        orderId?: string
      } | null {
        const eventId = uuidv4()
        const currency = getCurrencyCode()
        const baseData = {currency, eventId, source: location.origin}
        if (event === ECOMMERCE_EVENTS.PAGEVIEW) {
          if (cookies) trackPageView(params)
          return null
        }
        if (event === ECOMMERCE_EVENTS.VIEWCONTENT) {
          if (cookies) trackProductView(params, {eventId})
          const {product} = params

          if (!product) return null

          const items = [
            {id: product._id, quantity: 1, itemPrice: product.value, itemName: product.name}
          ]

          return {...baseData, items, totalPrice: product.value}
        }
        if (event === ECOMMERCE_EVENTS.INITCHECKOUT) {
          if (cookies) trackInitCheckout(params, {eventId})
          const {cart} = params

          return {
            ...baseData,
            items: Array.from(
              cart.items.map(item => {
                return {
                  id: item.product._id,
                  quantity: item.amount,
                  itemPrice: item.unitPrice,
                  itemName: item.product.name
                }
              })
            ),
            totalPrice: cart.itemsPrice
          }
        }
        if (event === ECOMMERCE_EVENTS.ADDTOCART) {
          if (cookies) trackAddToCart(params, {eventId})
          const {product, amount} = params

          const item: {
            unitPrice: number
            totalPrice: number
          } = params.item

          if (!product) return null

          const items = [
            {id: product._id, quantity: amount, itemPrice: item.unitPrice, itemName: product.name}
          ]
          return {...baseData, items, totalPrice: item.totalPrice}
        }
        if (event === ECOMMERCE_EVENTS.PURCHASE) {
          if (cookies) trackPurchase(params, {eventId})

          const {order} = params
          if (!order) return null

          return {
            ...baseData,
            orderId: order._id,
            totalPrice: order.totalPrice,
            ...(order.items && {
              items: Array.from(
                order.items.map(item => {
                  return {
                    id: item.product._id,
                    quantity: item.amount,
                    itemPrice: item.unitPrice,
                    itemName: item.product.name
                  }
                })
              )
            })
          }
        }
        return null
      }
      const serverData = getEventServerData(event, params)
      if (!serverData) return
      await serverSendEvent(event, mutate, serverData, initialData)
    } catch (error) {
      logger.warn('error useTrackEcommerceEvent', {error})
    }
  }

  return isAnalyticsV2Avail ? async () => {} : trackEcommerceEvent
}
