import {getReactGA} from '@helpers/getReactGA'
import getCurrencyCode from '@i18n/getCurrencyCode'

import {analyticsEventManager, providers, recommendedEvents} from './eventAnalytics'
import trackPageView from './trackPageView'

export default function trackProductView({product}: {product?: any}, {eventId}: {eventId: string}) {
  if (!product) return
  if (!product.availabilityAt) return

  const {finalPrice} = product.availabilityAt
  const currencyCode = getCurrencyCode()

  let gaData: any = {
    id: product._id,
    name: product.name,
    category: product.categories.length ? product.categories[0].internalName : null
  }

  const ga4Data: any = {
    items: [
      {
        item_id: product._id,
        item_name: product.name,
        category: product.categories.length ? product.categories[0].internalName : null,
        currency: currencyCode
      }
    ]
  }

  let pixelData: any = {
    content_name: product.name,
    content_type: 'product',
    content_ids: product._id
  }

  const gtmData: any = {
    event: recommendedEvents.SELECT_ITEM.gtm,
    ecommerce: {
      items: [{item_id: product._id, item_name: product.name}]
    }
  }

  if (finalPrice) {
    pixelData = {...pixelData, value: finalPrice, currency: currencyCode}
    ga4Data['items'] = [{...ga4Data['items'][0], price: finalPrice}]
    gtmData['ecommerce']['items'] = [{...gtmData['ecommerce']['items'][0], price: finalPrice}]
    gaData = {...gaData, price: finalPrice}
  }
  const ReactGA = getReactGA()
  ReactGA?.set({currencyCode: currencyCode})
  ReactGA?.plugin.execute('ec', 'addImpression', gaData)
  ReactGA?.plugin.execute('ec', 'setAction', 'detail')
  ReactGA?.pageview(window.location.pathname)

  const analyticPixelSendEvent = analyticsEventManager[providers.PIXEL]
  analyticPixelSendEvent('ViewContent', pixelData, {eventID: eventId})

  const analyticga4SendEvent = analyticsEventManager[providers.GA4]
  analyticga4SendEvent(recommendedEvents.SELECT_ITEM.ga4, ga4Data)

  const analyticsgtmSendEvent = analyticsEventManager[providers.GTM]
  analyticsgtmSendEvent(gtmData)

  trackPageView('/virtual/view_content')
}
