import {getReactGA} from '@helpers/getReactGA'
import getCurrencyCode from '@i18n/getCurrencyCode'

import {analyticsEventManager, providers, recommendedEvents} from './eventAnalytics'

export default function trackInitCheckout({cart}: {cart?: any}, {eventId}: {eventId: string}) {
  if (!cart) return
  const ReactGA = getReactGA()
  ReactGA?.plugin.execute('ec', 'setAction', 'checkout')
  ReactGA?.pageview('/checkout')

  const currency = getCurrencyCode()

  const analyticPixelSendEvent = analyticsEventManager[providers.PIXEL]
  const numItems = cart.items.reduce((sum, item) => item.amount + sum, 0)
  const pixelData = {
    value: cart.itemsPrice,
    num_items: numItems,
    currency
  }
  analyticPixelSendEvent(recommendedEvents.BEGIN_CHECKOUT.pixel, pixelData, {eventID: eventId})

  const items = Array.from(
    cart.items.map(item => {
      return {
        item_id: item.product._id,
        item_name: item.product.name,
        price: item.unitPrice,
        quantity: item.amount
      }
    })
  )

  const analyticga4SendEvent = analyticsEventManager[providers.GA4]
  const ga4Data = {
    currency,
    value: cart.totalPrice,
    items
  }
  analyticga4SendEvent(recommendedEvents.BEGIN_CHECKOUT.ga4, ga4Data)

  const analyticsgtmSendEvent = analyticsEventManager[providers.GTM]
  const gtmData = {
    event: recommendedEvents.BEGIN_CHECKOUT.gtm,
    ecommerce: {
      items
    }
  }
  analyticsgtmSendEvent(gtmData)
}
