import {getReactGA} from '@helpers/getReactGA'
import getCurrencyCode from '@i18n/getCurrencyCode'

import {analyticsEventManager, providers, recommendedEvents} from './eventAnalytics'
import trackPageView from './trackPageView'

export default function trackPurchase({order}: {order?: any}, {eventId}: {eventId: string}) {
  if (!order) return
  const currency = getCurrencyCode()
  const ReactGA = getReactGA()

  ReactGA?.plugin.execute('ec', 'clear', {})
  ReactGA?.set({currencyCode: currency})

  for (const item of order.items) {
    ReactGA?.plugin.execute('ec', 'addProduct', {
      id: item.product._id,
      name: item.product.name,
      category: item.product.categories.length ? item.product.categories[0].internalName : null,
      price: item.unitPrice,
      quantity: item.amount
    })
  }
  ReactGA?.plugin.execute('ec', 'setAction', 'purchase', {
    id: order._id,
    revenue: order.totalPrice,
    coupon: order.couponCode,
    shipping: order.deliveryFee
  })
  ReactGA?.pageview(window.location.pathname)

  const analyticPixelSendEvent = analyticsEventManager[providers.PIXEL]
  const pixelData = {
    value: order.totalPrice,
    num_items: order.totalItems,
    currency: currency,
    content_type: 'product',
    content_ids: Array.from(
      order.items.map(item => {
        return item.product._id
      })
    )
  }
  analyticPixelSendEvent(recommendedEvents.PURCHASE.pixel, pixelData, {eventID: eventId})

  const items = Array.from(
    order.items.map(item => {
      return {
        item_id: item.product._id,
        item_name: item.product.name,
        item_category: item.product.categories.length
          ? item.product.categories[0].internalName
          : null,
        price: item.unitPrice,
        quantity: item.amount
      }
    })
  )

  const analyticga4SendEvent = analyticsEventManager[providers.GA4]
  const ga4Data = {
    currency: currency,
    transaction_id: order._id,
    value: order.totalPrice,
    items
  }
  analyticga4SendEvent(recommendedEvents.PURCHASE.ga4, ga4Data)

  const analyticsgtmSendEvent = analyticsEventManager[providers.GTM]
  const gtmData = {
    event: recommendedEvents.PURCHASE.gtm,
    ecommerce: {
      currency: currency,
      transaction_id: order._id,
      value: order.totalPrice,
      items
    }
  }
  analyticsgtmSendEvent(gtmData)

  trackPageView('/virtual/purchase')
}
